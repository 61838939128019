<template>
  <div class="profile-licenses">
    <div class="content-wrapper">
      <div class="content">
        <!-- Top Bar -->
        <div class="top-bar">
          <div class="back" @click="showComponent('Profile-Home')">
            <img src="@/assets/icons/Arrow_Left.svg" alt="Back" />
            <p>Zurück</p>
          </div>
        </div>

        <!-- Content -->
        <simplebar data-simplebar-auto-hide="false" class="licenses__wrapper">
          <h1>Lizenzvereinbarungen</h1>
          <h2>1.1 Avatare</h2>
          <div class="select-avatar">
            <div class="avatar-img-wrapper">
              <img src="@/assets/icons/avatar_batman.svg" alt="Avatar" />
            </div>
            <div class="avatar-img-wrapper">
              <img src="@/assets/icons/avatar_heisenberg.svg" alt="Avatar" />
            </div>
            <div class="avatar-img-wrapper">
              <img src="@/assets/icons/avatar_santa.svg" alt="Avatar" />
            </div>
            <div class="avatar-img-wrapper">
              <img src="@/assets/icons/avatar_wrestler.svg" alt="Avatar" />
            </div>
            <div class="avatar-img-wrapper">
              <img src="@/assets/icons/avatar_sloth.svg" alt="Avatar" />
            </div>
            <div class="avatar-img-wrapper">
              <img src="@/assets/icons/avatar_hipster.svg" alt="Avatar" />
            </div>
            <div class="avatar-img-wrapper">
              <img src="@/assets/icons/avatar_jason.svg" alt="Avatar" />
            </div>
            <div class="avatar-img-wrapper">
              <img src="@/assets/icons/avatar_zorro.svg" alt="Avatar" />
            </div>
            <div class="avatar-img-wrapper">
              <img src="@/assets/icons/avatar_pirate.svg" alt="Avatar" />
            </div>
          </div>
          <p>Die unter 1.1 dargestellten Icons wurden unverändert übernommen und wurden designed von</p>
          <p>Laura Reen, <a target="_blank" href="http://laurareen.com">laurareen.com</a></p>
          <p>
            Icon Lizenz: <a target="_blank" href="https://creativecommons.org/licenses/by-nc/3.0/">Attribution-NonCommercial 3.0 Unported (CC BY-NC 3.0)</a>
          </p>
          <p class="underline">Einzelnachweise:</p>
          <div class="ul">
            <li>
              Avatar Batman:
              <a target="_blank" href="https://www.iconfinder.com/icons/4043232/avatar_batman_comics_hero_icon">Quelle</a>
            </li>
            <li>
              Avatar Heisenberg:
              <a target="_blank" href="https://www.iconfinder.com/icons/4043240/avatar_bad_breaking_chemisrty_heisenberg_icon">Quelle</a>
            </li>
            <li>
              Avatar Santa:
              <a target="_blank" href="https://www.iconfinder.com/icons/4043276/christmas_clous_santa_icon">Quelle</a>
            </li>
            <li>
              Avatar Wrestler:
              <a target="_blank" href="https://www.iconfinder.com/icons/4043267/fighter_luchador_man_wrestler_icon">Quelle</a>
            </li>
            <li>
              Avatar Sloth:
              <a target="_blank" href="https://www.iconfinder.com/icons/4043272/avatar_lazybones_sloth_sluggard_icon">Quelle</a>
            </li>
            <li>
              Avatar Hipster:
              <a target="_blank" href="https://www.iconfinder.com/icons/4043255/beard_hipster_male_man_icon">Quelle</a>
            </li>
            <li>
              Avatar Jason:
              <a target="_blank" href="https://www.iconfinder.com/icons/4043253/friday_halloween_jason_movie_icon">Quelle</a>
            </li>
            <li>
              Avatar Zorro:
              <a target="_blank" href="https://www.iconfinder.com/icons/4043245/avatar_coffee_cup_zorro_icon">Quelle</a>
            </li>
            <li>
              Avatar Pirate Cactus:
              <a target="_blank" href="https://www.iconfinder.com/icons/4043242/avatar_cacti_cactus_pirate_icon">Quelle</a>
            </li>
          </div>
          <p class="contact">Bei fehlerhaften Links, Fragen oder Anmerkungen: <a href="mailto:mail@teamee.de">mail@teamee.de</a></p>
        </simplebar>
      </div>
    </div>
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";

export default {
  name: "Profile-Legal",
  components: {
    simplebar,
  },
  data() {
    return {
      showSettings: false,
    };
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_PROFILE_COMPONENT", name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.profile-licenses {
  min-height: calc(100vh - 83px);
  padding-top: 200px;
  display: flex;
  align-items: flex-end;
  @media screen and (max-width: 960px) {
    height: 100vh;
    padding-top: 0;
    align-items: flex-start;
    padding-top: 92px;
    padding-bottom: 83px;
  }
  @media screen and (max-height: 667px) {
    padding-bottom: 60px;
  }
  .content-wrapper {
    width: 100%;
    background-color: $darker-navy-blue;
    height: calc(100vh - 283px);
    @media screen and (max-width: 960px) {
      height: 100%;
    }
    .content {
      position: relative;
      width: 800px;
      height: 100%;
      margin: 0 auto;
      padding-top: 8px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 960px) {
        width: 100%;
        max-width: 800px;
        padding: 0 24px;
      }
      .top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        border-bottom: 1px solid $white;
        @media screen and (max-width: 960px) {
          padding-top: 8px;
        }
        .back {
          padding: 0 0 4px 4px;
          display: flex;
          align-items: center;
          cursor: pointer;
          width: fit-content;
          &:hover {
            text-decoration: underline;
          }
          img {
            height: 24px;
            margin-right: 16px;
          }
        }
      }
      .licenses__wrapper {
        height: calc(100vh - 432px);
        padding-right: 12px;
        @media screen and (max-width: 960px) {
          height: calc(100vh - 342px);
        }
        @media screen and (max-height: 667px) {
          height: calc(100vh - 302px);
        }
        h1 {
          margin-bottom: 12px;
          font-size: 36px;
          line-height: 1.5;
          @media screen and (max-width: 960px) {
            font-size: 22px;
          }
        }
        h2,
        h3 {
          margin: 12px 0;
          font-size: 24px;
          line-height: 1.5;
          @media screen and (max-width: 960px) {
            font-size: 18px;
          }
        }
        h1,
        h2,
        h3,
        h4 {
          color: white;
        }
        p {
          margin: 10px 0;
          line-height: 1.5;
        }
        a {
          color: #ff6e00;
          display: inline;
          word-break: break-all;
          &:hover {
            text-decoration: underline;
          }
        }
        .ul {
          list-style-type: disc !important;
          li {
            margin-bottom: 6px;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
        .contact {
          margin-top: 32px;
        }
        .underline {
          text-decoration: underline;
        }
        .select-avatar {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 16px;
          .avatar-img-wrapper {
            margin-right: 8px;
            &:last-of-type {
              margin-right: 0;
            }
            img {
              width: 48px;
            }
          }
        }
      }
    }
  }
}
</style>
