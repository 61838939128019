<template>
  <div class="profile">
    <transition name="fade" mode="out-in">
      <component :is="component" />
    </transition>
  </div>
</template>

<script>
import ProfileHome from "@/components/Profile-Home.vue";
import ProfileStatus from "@/components/Profile-Status.vue";
import ProfileAvatar from "@/components/Profile-Avatar.vue";
import ProfilePassword from "@/components/Profile-Password.vue";
import ProfileDeleteAcc from "@/components/Profile-DeleteAcc.vue";
import ProfileLegal from "@/components/Profile-Legal.vue";
import ProfileBlockedUsers from "@/components/Profile-Blocked-Users.vue";
import ProfileLicenses from "@/components/Profile-Licenses.vue";

export default {
  name: "Profile",
  components: {
    ProfileHome,
    ProfileStatus,
    ProfileAvatar,
    ProfilePassword,
    ProfileDeleteAcc,
    ProfileLegal,
    ProfileBlockedUsers,
    ProfileLicenses,
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_PROFILE_COMPONENT", name);
    },
  },
  computed: {
    component() {
      return this.$store.getters.PROFILECOMPONENT;
    },
  },
};
</script>
