<template>
  <div class="profile-password">
    <div class="content-wrapper">
      <div class="content">
        <!-- Top Bar -->
        <div class="top-bar">
          <div class="back" @click="showComponent('Profile-Home')">
            <img src="@/assets/icons/Arrow_Left.svg" alt="Back" />
            <p>Zurück</p>
          </div>
        </div>

        <div class="password__wrapper">
          <p>Passwort ändern</p>
          <div class="change-password">
            <div class="input__text__label">
              <input
                class="input__text floating-input"
                :class="{ input__text__danger: $v.password_old.$error || errors.password_old }"
                type="password"
                title="Input Text"
                placeholder=" "
                v-model.trim="$v.password_old.$model"
              />
              <label>aktuelles Passwort</label>
            </div>
            <!-- Error Message -->
            <div v-if="$v.password_old.$error || errors.password_old" class="error">
              <li v-if="errors.password_old" class="error-msg">{{ errors.password_old }}</li>
              <li v-if="!$v.password_old.required" class="error-msg">Gib dein altes Passwort an</li>
            </div>

            <div class="input__text__label">
              <input
                class="input__text floating-input"
                :class="{ input__text__danger: $v.password.$error || errors.password }"
                type="password"
                title="Input Text"
                placeholder=" "
                v-model.trim="$v.password.$model"
              />
              <label>neues Passwort</label>
            </div>
            <!-- Error Message -->
            <div v-if="$v.password.$error || errors.password" class="error">
              <li v-if="errors.password" class="error-msg">{{ errors.password }}</li>
              <li v-if="!$v.password.required" class="error-msg">Gib ein neues, sicheres Passwort an</li>
              <li v-if="!$v.password.checkPasswordPattern" class="error-msg">
                Dein Passwort sollte aus mindestend 6 Buchstaben, Zahlen und Sonderzeichen bestehen
              </li>
            </div>

            <div class="input__text__label">
              <input
                class="input__text floating-input"
                :class="{ input__text__danger: $v.password_confirmation.$error || errors.password_confirmation }"
                type="password"
                title="Input Text"
                placeholder=" "
                v-model.trim="$v.password_confirmation.$model"
              />
              <label>neues Passwort wiederholen</label>
            </div>
            <!-- Error Message -->
            <div v-if="$v.password_confirmation.$error || errors.password__confirmation" class="error">
              <li v-if="errors.password_confirmation" class="error-msg">{{ errors.password_confirmation[0] }}</li>
              <li v-if="!$v.password_confirmation.required" class="error-msg">Bitte bestätige dein Passwort</li>
              <li v-if="!$v.password_confirmation.sameAsPassword" class="error-msg">Deine Passwortangaben müssen übereinstimmen</li>
            </div>
            <p class="success-msg" v-if="successMsg">{{ successMsg }}</p>
          </div>
        </div>

        <button class="button__cta" @click="changePassword" :disabled="isLoading">
          <span class="loader" v-if="isLoading"></span>
          <span class="txt" v-else>Änderungen übernehmen</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "Profile-Password",
  data() {
    return {
      password_old: "",
      password: "",
      password_confirmation: "",
      isLoading: false,
      errors: [],
      successMsg: "",
    };
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_PROFILE_COMPONENT", name);
    },
    changePassword() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        // if Form is valid -> submit Form
        this.isLoading = true;

        this.$store
          .dispatch("changePassword", {
            password_old: this.password_old,
            password: this.password,
            password_confirmation: this.password_confirmation,
          })
          .then((response) => {
            this.isLoading = false;
            this.errors = [];
            this.successMsg = response.data.msg;
          })
          .catch((error) => {
            this.isLoading = false;
            // wrong Credentials
            if (error.response.status === 422) {
              this.successMsg = "";
              this.errors = error.response.data;
            }
          });
      }
    },
  },
  validations: {
    password_old: {
      required,
    },
    password: {
      required,
      checkPasswordPattern(password) {
        return (
          /[a-z]/.test(password) && // checks for a-z
          /[0-9]/.test(password) && // checks for 0-9
          /\W|_/.test(password) && // checks for special char
          password.length >= 6
        );
      },
    },
    password_confirmation: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.profile-password {
  min-height: calc(100vh - 83px);
  padding-top: 200px;
  display: flex;
  align-items: flex-end;
  @media screen and (max-width: 960px) {
    height: 100vh;
    padding-top: 0;
    align-items: flex-start;
    padding-top: 92px;
    padding-bottom: 83px;
  }
  @media screen and (max-height: 667px) {
    padding-bottom: 60px;
  }
  .content-wrapper {
    width: 100%;
    background-color: $darker-navy-blue;
    height: calc(100vh - 283px);
    @media screen and (max-width: 960px) {
      height: 100%;
    }
    .content {
      width: 800px;
      height: 100%;
      margin: 0 auto;
      padding-top: 8px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 960px) {
        width: 100%;
        max-width: 800px;
        padding: 0 24px;
      }
      .top-bar {
        margin-bottom: 12px;
        border-bottom: 1px solid $white;
        @media screen and (max-width: 960px) {
          padding-top: 8px;
        }
        .back {
          padding: 0 0 4px 4px;
          display: flex;
          align-items: center;
          cursor: pointer;
          width: fit-content;
          &:hover {
            text-decoration: underline;
          }
          img {
            height: 24px;
            margin-right: 16px;
          }
        }
      }
      .password__wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        .change-password {
          margin-top: 24px;
          .error {
            margin-top: 8px;
            margin-left: 16px;
            .error-msg {
              font-size: 14px;
              color: $red;
            }
          }
          .input__text__label {
            margin-top: 28px;
          }
        }
        .success-msg {
          text-align: center;
          margin-top: 40px;
        }
      }
      .button__cta {
        width: 400px;
        align-self: center;
        margin-top: auto;
        margin-bottom: 80px;
        @media screen and (max-width: 960px) {
          width: 100%;
          max-width: 400px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
