<template>
  <div class="profile-deleteacc">
    <div class="content-wrapper">
      <div class="content">
        <!-- Top Bar -->
        <div class="top-bar">
          <div class="back" @click="showComponent('Profile-Home')">
            <img src="@/assets/icons/Arrow_Left.svg" alt="Back" />
            <p>Zurück</p>
          </div>
        </div>

        <div class="deleteacc__wrapper">
          <p>Account löschen</p>
          <div class="delete-acc">
            <p>Bist du sicher, dass du deinen Account dauerhaft löschen möchtest?</p>
            <p>Dieser Schritt ist unwiderrufbar und dein Account kann nicht wiederhergestellt werden!</p>
            <div class="input__text__label">
              <input
                class="input__text floating-input"
                :class="{ input__text__danger: $v.password.$error }"
                placeholder=" "
                type="password"
                title="Input Text"
                v-model.trim="$v.password.$model"
              />
              <label>Passwort verifizieren</label>
            </div>
            <!-- Error Message -->
            <div v-if="$v.password.$error || errors.password" class="error">
              <li v-if="!$v.password.required || errors.password" class="error-msg">Bitte gib dein Passwort an</li>
            </div>
          </div>
        </div>

        <button class="button__danger" @click="deleteAccount" :disabled="isLoading">
          <span class="loader" v-if="isLoading"></span>
          <span class="txt" v-else>Account dauerhaft löschen</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "Profile-DeleteAcc",
  data() {
    return {
      password: "",
      isLoading: false,
      errors: [],
    };
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_PROFILE_COMPONENT", name);
    },
    deleteAccount() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        // if Form is valid -> submit Form
        this.isLoading = true;

        this.$store
          .dispatch("deleteAccount", {
            password: this.password,
          })
          .then(() => {
            this.isLoading = false;
            this.errors = [];
            localStorage.clear();
            localStorage.setItem("thankyou", "true");
            this.$router.push("/ThankYou");
          })
          .catch((error) => {
            this.isLoading = false;
            // wrong Password
            if (error.response.status === 422) {
              this.errors = error.response.data;
            }
          });
      }
    },
  },
  validations: {
    password: {
      required,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.profile-deleteacc {
  min-height: calc(100vh - 83px);
  padding-top: 200px;
  display: flex;
  align-items: flex-end;
  @media screen and (max-width: 960px) {
    height: 100vh;
    padding-top: 0;
    align-items: flex-start;
    padding-top: 92px;
    padding-bottom: 83px;
  }
  @media screen and (max-height: 667px) {
    padding-bottom: 60px;
  }
  @media screen and (max-height: 568px) {
    height: auto;
  }
  .content-wrapper {
    width: 100%;
    background-color: $darker-navy-blue;
    height: calc(100vh - 283px);
    @media screen and (max-width: 960px) {
      height: 100%;
    }
    @media screen and (max-height: 568px) {
      padding-bottom: 0;
    }
    .content {
      width: 800px;
      height: 100%;
      margin: 0 auto;
      padding-top: 8px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 960px) {
        width: 100%;
        max-width: 800px;
        padding: 0 24px;
      }
      .top-bar {
        margin-bottom: 12px;
        border-bottom: 1px solid $white;
        @media screen and (max-width: 960px) {
          padding-top: 8px;
        }
        @media screen and (max-height: 568px) {
          position: sticky;
          top: 92px;
          background-color: $darker-navy-blue;
        }
        .back {
          padding: 0 0 4px 4px;
          display: flex;
          align-items: center;
          cursor: pointer;
          width: fit-content;
          &:hover {
            text-decoration: underline;
          }
          img {
            height: 24px;
            margin-right: 16px;
          }
        }
      }
      .deleteacc__wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        .delete-acc {
          margin-top: 24px;
          .input__text__label {
            margin-top: 120px;
            @media screen and (max-width: 960px) {
              margin-top: 60px;
            }
            @media screen and (max-height: 768px) {
              margin-top: 30px;
            }
          }
          .error {
            margin-top: 8px;
            margin-left: 16px;
            .error-msg {
              font-size: 14px;
              color: $red;
            }
          }
        }
      }
      .button__danger {
        width: 400px;
        align-self: center;
        margin-top: auto;
        margin-bottom: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 960px) {
          width: 100%;
          max-width: 400px;
          margin-bottom: 20px;
        }
        @media screen and (max-height: 568px) {
          margin-top: 20px;
        }
        .loader::after {
          border-color: $white transparent;
        }
      }
    }
  }
}
</style>
