<template>
  <div class="profile-status">
    <div class="content-wrapper">
      <div class="content">
        <!-- Top Bar -->
        <div class="top-bar">
          <div class="back" @click="showComponent('Profile-Home')">
            <img src="@/assets/icons/Arrow_Left.svg" alt="Back" />
            <p>Zurück</p>
          </div>
        </div>

        <div class="status__wrapper">
          <p>Status</p>
          <div class="select-status">
            <div class="input">
              <div class="status__options">
                <div class="wrapper-option">
                  <div class="status-indicator indicator_1"></div>
                  <label class="label__online" for="status__1"
                    >Online
                    <input id="status__1" value="status_online" type="radio" name="status" :checked="status === 0" @click="newStatus = 0" />
                    <span class="checkmark"></span>
                  </label>
                </div>

                <div class="wrapper-option">
                  <div class="status-indicator indicator_2"></div>
                  <label for="status__2"
                    >Beschäftigt
                    <input id="status__2" value="status_busy" type="radio" name="status" :checked="status === 1" @click="newStatus = 1" />
                    <span class="checkmark"></span>
                  </label>
                </div>

                <div class="wrapper-option">
                  <div class="status-indicator indicator_3"></div>
                  <label for="status__3"
                    >Bitte nicht stören
                    <input id="status__3" value="status_dont-disturb" type="radio" name="status" :checked="status === 2" @click="newStatus = 2" />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button class="button__cta" @click="setNewStatus">Änderungen übernehmen</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Profile-Status",
  data() {
    return {
      newStatus: null,
    };
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_PROFILE_COMPONENT", name);
    },
    setNewStatus() {
      // set new status and insert into database
    },
  },
  computed: {
    status() {
      return 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.profile-status {
  min-height: calc(100vh - 83px);
  padding-top: 200px;
  display: flex;
  align-items: flex-end;
  @media screen and (max-width: 960px) {
    height: 100vh;
    padding-top: 0;
    align-items: flex-start;
    padding-top: 92px;
    padding-bottom: 83px;
  }
  @media screen and (max-height: 667px) {
    padding-bottom: 60px;
  }
  .content-wrapper {
    width: 100%;
    background-color: $darker-navy-blue;
    height: calc(100vh - 283px);
    @media screen and (max-width: 960px) {
      height: 100%;
    }
    .content {
      width: 800px;
      height: 100%;
      margin: 0 auto;
      padding-top: 8px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 960px) {
        width: 100%;
        max-width: 800px;
        padding: 0 24px;
      }
      .top-bar {
        margin-bottom: 12px;
        border-bottom: 1px solid $white;
        @media screen and (max-width: 960px) {
          padding-top: 8px;
        }
        .back {
          padding: 0 0 4px 4px;
          display: flex;
          align-items: center;
          cursor: pointer;
          width: fit-content;
          &:hover {
            text-decoration: underline;
          }
          img {
            height: 24px;
            margin-right: 16px;
          }
        }
      }
      .status__wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .select-status {
          display: flex;
          flex-direction: column;
          .status__options {
            width: 200px;
            .wrapper-option {
              display: flex;
              align-items: center;
              margin-bottom: 4px;
              .status-indicator {
                width: 12px;
                height: 12px;
                transform: translateX(-8px);
                border-radius: 100%;
              }
              .indicator_1 {
                background-color: $green;
              }
              .indicator_2 {
                background-color: $yellow;
              }
              .indicator_3 {
                background-color: $red;
              }
            }
          }
          label {
            flex-grow: 1;
          }
        }
      }
      .button__cta {
        width: 400px;
        align-self: center;
        margin-top: auto;
        margin-bottom: 80px;
        @media screen and (max-width: 960px) {
          width: 100%;
          max-width: 400px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
