<template>
  <div class="profile-blocked-users">
    <div class="content-wrapper">
      <div class="content">
        <!-- Top Bar -->
        <div class="top-bar">
          <div class="back" @click="showComponent('Profile-Home')">
            <img src="@/assets/icons/Arrow_Left.svg" alt="Back" />
            <p>Zurück</p>
          </div>
        </div>

        <div class="blocked-users__wrapper">
          <p>User blockieren</p>
          <form @submit.prevent="searchUser">
            <!-- added name="search" for Safari, since placeholder="Username" triggers Safari's Autocomplete Password Manager -->
            <input class="input__text__rounded" type="text" name="search" placeholder="Username" title="Suche nach Username" v-model="username" />
            <button class="button__cta" :disabled="isLoading">
              <span class="loader" v-if="isLoading"></span>
              <span class="txt" v-else>Suchen</span>
            </button>
          </form>

          <!-- Search Results -->
          <div class="search-result" v-if="searchResult">
            <div class="contact" @click="blockUser">
              <div class="info">
                <img :src="require(`@/assets/icons/${searchResult.avatar}`)" alt="Avatar" />
                <p class="name">{{ searchResult.username }}</p>
              </div>
              <img class="add" v-if="!blockingUser" src="@/assets/icons/block.svg" alt="Add Contact" />
              <span class="loader" v-else></span>
            </div>
          </div>

          <!-- List of blocked Users -->
          <ul class="blocked-users-list" v-if="blockedUsers.length">
            <p>Blockierte User:</p>
            <li v-for="(item, index) in blockedUsers" :key="index" @click="unblockUser(item)">
              <div class="contact">
                <div class="info">
                  <img :src="require(`@/assets/icons/${item.avatar}`)" alt="Avatar" />
                  <p class="name">{{ item.username }}</p>
                </div>
                <div class="unblock" v-if="!unblockingUser || currentlyUnblocking !== item.id">Entblocken</div>
                <span class="loader" v-else></span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Profile-Blocked-Users",
  data() {
    return {
      isLoading: false,
      username: "",
      searchResult: null,
      blockingUser: false,
      unblockingUser: false,
      currentlyUnblocking: null,
    };
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_PROFILE_COMPONENT", name);
    },
    searchUser() {
      if (this.username.length < 2) {
        this.flash("Bitte gib einen gültigen Username ein.", "warning", { timeout: 3500 });
      } else {
        this.isLoading = true;
        this.$store
          .dispatch("fetchUser", {
            username: this.username,
          })
          .then((response) => {
            this.isLoading = false;
            this.errMsg = "";
            this.searchResult = response.data;
          })
          .catch((error) => {
            if (error.response.status === 403) {
              let troll = {
                username: "I see what You did there!",
                avatar: "avatar_trollface.svg",
              };
              this.searchResult = troll;
              this.isLoading = false;
            }
            if (error.response.status === 404) {
              this.isLoading = false;
              this.flash(error.response.data.msg, "error", { timeout: 3500 });
              this.searchResult = null;
            }
            if (error.response.status === 500) {
              this.isLoading = false;
              this.flash("Hoppla, hier gab es ein Problem... Versuche es noch einmal.", "error", { timeout: 3500 });
              this.searchResult = null;
            }
          });
      }
    },
    blockUser() {
      if (!this.blockingUser && this.searchResult.id !== undefined) {
        this.blockingUser = true;
        this.$store
          .dispatch("blockUser", { userId: this.searchResult.id })
          .then((response) => {
            this.$store.commit("SET_BLOCKED_USERS", response.data);
            this.blockingUser = false;
            this.flash(response.data.msg, "success", { timeout: 3500 });
            this.$store.dispatch("getBlockedUsers");
          })
          .catch((error) => {
            this.blockingUser = false;
            this.flash(error.response.data.msg, "error", { timeout: 3500 });
            this.searchResult = null;
          });
      } else {
        this.flash("Du kannst dich nicht selbst blockieren", "warning", { timeout: 3500 });
      }
    },
    unblockUser(item) {
      if (!this.unblockingUser) {
        this.currentlyUnblocking = item.id;
        this.unblockingUser = true;
        this.$store
          .dispatch("unblockUser", { userId: item.id })
          .then((response) => {
            this.unblockingUser = false;
            this.currentlyUnblocking = null;
            this.flash(response.data.msg, "success", { timeout: 5000 });
            this.$store.dispatch("getBlockedUsers");
          })
          .catch(() => {
            this.unblockingUser = false;
            this.currentlyUnblocking = null;
            this.flash("Hoppla, hier gab es ein Problem... Versuche es noch einmal.", "error", { timeout: 3500 });
          });
      }
    },
  },
  computed: {
    blockedUsers() {
      return this.$store.getters.BLOCKED_USERS;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.profile-blocked-users {
  min-height: calc(100vh - 83px);
  padding-top: 200px;
  display: flex;
  align-items: flex-end;
  @media screen and (max-width: 960px) {
    height: 100vh;
    padding-top: 0;
    align-items: flex-start;
    padding-top: 92px;
    padding-bottom: 83px;
  }
  @media screen and (max-height: 667px) {
    padding-bottom: 60px;
  }
  .content-wrapper {
    width: 100%;
    background-color: $darker-navy-blue;
    height: calc(100vh - 283px);
    @media screen and (max-width: 960px) {
      height: 100%;
    }
    .content {
      width: 800px;
      height: 100%;
      margin: 0 auto;
      padding-top: 8px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 960px) {
        width: 100%;
        max-width: 800px;
        padding: 0 24px;
      }
      .top-bar {
        margin-bottom: 12px;
        border-bottom: 1px solid $white;
        @media screen and (max-width: 960px) {
          padding-top: 8px;
        }
        .back {
          padding: 0 0 4px 4px;
          display: flex;
          align-items: center;
          cursor: pointer;
          width: fit-content;
          &:hover {
            text-decoration: underline;
          }
          img {
            height: 24px;
            margin-right: 16px;
          }
        }
      }
      .blocked-users__wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        form {
          display: flex;
          button {
            height: 49px;
            margin-left: 8px;
            @media screen and (max-width: 600px) {
              min-width: auto;
              width: 80px;
            }
          }
        }
        .search-result {
          width: 480px;
          margin: 24px auto;
          @media screen and (max-width: 960px) {
            max-width: 480px;
            width: 100%;
          }
        }
        .blocked-users-list {
          margin-top: 24px;
          li {
            list-style: none;
            margin-bottom: 8px;
          }
        }
        .contact {
          cursor: pointer;
          width: 100%;
          border: 1px solid $light-grey;
          display: flex;
          border-radius: 5px;
          padding: 6px 12px;
          justify-content: space-between;
          align-items: center;
          transition: all 0.2s ease-in-out;
          &:hover {
            background-color: $light-grey;
            .unblock {
              color: $green;
            }
          }
          .info {
            display: flex;
            align-items: center;
            img {
              height: 48px;
              width: 48px;
              margin-right: 20px;
            }
          }
          .add {
            width: 40px;
            height: 40px;
          }
          .loader {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
</style>
