<template>
  <div class="profile-avatar">
    <div class="content-wrapper">
      <div class="content">
        <!-- Top Bar -->
        <div class="top-bar">
          <div class="back" @click="showComponent('Profile-Home')">
            <img src="@/assets/icons/Arrow_Left.svg" alt="Back" />
            <p>Zurück</p>
          </div>
        </div>

        <div class="avatar__wrapper">
          <p>Avatar ändern</p>
          <simplebar data-simplebar-auto-hide="false" data-simplebar class="select-avatar__wrapper" ref="messagesContainer">
            <div class="select-avatar">
              <div
                class="avatar-wrapper"
                :class="{ selected: (avatarID === 1 && temporaryAvatarID === null) || temporaryAvatarID === 1 }"
                @click="setCurrentAvatarID(1)"
              >
                <div class="avatar-img-wrapper">
                  <img src="@/assets/icons/avatar_batman.svg" alt="Avatar" />
                </div>
              </div>
              <div
                class="avatar-wrapper"
                :class="{ selected: (avatarID === 2 && temporaryAvatarID === null) || temporaryAvatarID === 2 }"
                @click="setCurrentAvatarID(2)"
              >
                <div class="avatar-img-wrapper">
                  <img src="@/assets/icons/avatar_heisenberg.svg" alt="Avatar" />
                </div>
              </div>
              <div
                class="avatar-wrapper"
                :class="{ selected: (avatarID === 3 && temporaryAvatarID === null) || temporaryAvatarID === 3 }"
                @click="setCurrentAvatarID(3)"
              >
                <div class="avatar-img-wrapper">
                  <img src="@/assets/icons/avatar_santa.svg" alt="Avatar" />
                </div>
              </div>
              <div
                class="avatar-wrapper"
                :class="{ selected: (avatarID === 4 && temporaryAvatarID === null) || temporaryAvatarID === 4 }"
                @click="setCurrentAvatarID(4)"
              >
                <div class="avatar-img-wrapper">
                  <img src="@/assets/icons/avatar_wrestler.svg" alt="Avatar" />
                </div>
              </div>
              <div
                class="avatar-wrapper"
                :class="{ selected: (avatarID === 5 && temporaryAvatarID === null) || temporaryAvatarID === 5 }"
                @click="setCurrentAvatarID(5)"
              >
                <div class="avatar-img-wrapper">
                  <img src="@/assets/icons/avatar_sloth.svg" alt="Avatar" />
                </div>
              </div>
              <div
                class="avatar-wrapper"
                :class="{ selected: (avatarID === 6 && temporaryAvatarID === null) || temporaryAvatarID === 6 }"
                @click="setCurrentAvatarID(6)"
              >
                <div class="avatar-img-wrapper">
                  <img src="@/assets/icons/avatar_hipster.svg" alt="Avatar" />
                </div>
              </div>
              <div
                class="avatar-wrapper"
                :class="{ selected: (avatarID === 7 && temporaryAvatarID === null) || temporaryAvatarID === 7 }"
                @click="setCurrentAvatarID(7)"
              >
                <div class="avatar-img-wrapper">
                  <img src="@/assets/icons/avatar_jason.svg" alt="Avatar" />
                </div>
              </div>
              <div
                class="avatar-wrapper"
                :class="{ selected: (avatarID === 8 && temporaryAvatarID === null) || temporaryAvatarID === 8 }"
                @click="setCurrentAvatarID(8)"
              >
                <div class="avatar-img-wrapper">
                  <img src="@/assets/icons/avatar_zorro.svg" alt="Avatar" />
                </div>
              </div>
              <div
                class="avatar-wrapper"
                :class="{ selected: (avatarID === 9 && temporaryAvatarID === null) || temporaryAvatarID === 9 }"
                @click="setCurrentAvatarID(9)"
              >
                <div class="avatar-img-wrapper">
                  <img src="@/assets/icons/avatar_pirate.svg" alt="Avatar" />
                </div>
              </div>
            </div>
          </simplebar>
        </div>
        <button class="button__cta" @click="setNewAvatar" :disabled="isLoading">
          <span class="loader" v-if="isLoading"></span>
          <span class="txt" v-else>Änderungen übernehmen</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";

export default {
  name: "Profile-Avatar",
  data() {
    return {
      temporaryAvatarID: null,
      isLoading: false,
    };
  },
  components: {
    simplebar,
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_PROFILE_COMPONENT", name);
    },
    setCurrentAvatarID(id) {
      this.temporaryAvatarID = id;
    },
    setNewAvatar() {
      // store new avatar id in database and return to Profile-Home Component
      this.isLoading = true;

      this.$store
        .dispatch("setAvatar", { avatar: this.newAvatar })
        .then(async (response) => {
          this.$store.commit("SET_USER_AVATAR", response.data);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    avatarID() {
      const avatar = this.$store.getters.USER.avatar;

      switch (avatar) {
        case "avatar_batman.svg":
          return 1;
        case "avatar_heisenberg.svg":
          return 2;
        case "avatar_santa.svg":
          return 3;
        case "avatar_wrestler.svg":
          return 4;
        case "avatar_sloth.svg":
          return 5;
        case "avatar_hipster.svg":
          return 6;
        case "avatar_jason.svg":
          return 7;
        case "avatar_zorro.svg":
          return 8;
        case "avatar_pirate.svg":
          return 9;
        default:
          return 1;
      }
    },
    newAvatar() {
      const avatarID = this.temporaryAvatarID;

      switch (avatarID) {
        case 1:
          return "avatar_batman.svg";
        case 2:
          return "avatar_heisenberg.svg";
        case 3:
          return "avatar_santa.svg";
        case 4:
          return "avatar_wrestler.svg";
        case 5:
          return "avatar_sloth.svg";
        case 6:
          return "avatar_hipster.svg";
        case 7:
          return "avatar_jason.svg";
        case 8:
          return "avatar_zorro.svg";
        case 9:
          return "avatar_pirate.svg";
        default:
          return "avatar_batman.svg";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.profile-avatar {
  min-height: calc(100vh - 83px);
  padding-top: 200px;
  display: flex;
  align-items: flex-end;
  @media screen and (max-width: 960px) {
    height: 100vh;
    padding-top: 0;
    align-items: flex-start;
    padding-top: 92px;
    padding-bottom: 83px;
  }
  @media screen and (max-height: 667px) {
    padding-bottom: 60px;
  }
  @media screen and (max-height: 568px) {
    height: auto;
  }
  .content-wrapper {
    width: 100%;
    background-color: $darker-navy-blue;
    height: calc(100vh - 283px);
    @media screen and (max-width: 960px) {
      height: 100%;
    }
    .content {
      width: 800px;
      height: 100%;
      margin: 0 auto;
      padding-top: 8px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 960px) {
        width: 100%;
        max-width: 800px;
        padding: 0 24px;
      }
      .top-bar {
        margin-bottom: 12px;
        border-bottom: 1px solid $white;
        @media screen and (max-width: 960px) {
          padding-top: 8px;
          position: sticky;
          top: 92px;
          background-color: $darker-navy-blue;
        }
        .back {
          padding: 0 0 4px 4px;
          display: flex;
          align-items: center;
          cursor: pointer;
          width: fit-content;
          &:hover {
            text-decoration: underline;
          }
          img {
            height: 24px;
            margin-right: 16px;
          }
        }
      }
      .avatar__wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 24px;
        @media screen and (max-width: 640px) {
          flex-direction: column;
        }
        .select-avatar__wrapper {
          width: 306px;
          max-height: calc(100vh - 490px);
          align-self: center;
          @media screen and (max-width: 640px) {
            margin-top: 24px;
          }
          @media screen and (max-height: 667px) {
            max-height: calc(100vh - 360px);
          }
          @media screen and (max-height: 568px) {
            max-height: calc(100vh - 374px);
          }
        }
        .select-avatar {
          width: 288px;

          display: grid;
          gap: 12px;
          grid-template-columns: repeat(3, 1fr);
          .selected {
            background: $gradient;
          }
          .avatar-wrapper {
            width: 88px;
            height: 88px;
            border-radius: 10px;
            background-color: $white;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-height: 568px) {
              width: 82px;
              height: 82px;
            }
            &:hover {
              background: $gradient;
            }
            .avatar-img-wrapper {
              width: 84px;
              height: 84px;
              background-color: $darker-navy-blue;
              border-radius: 9px;
              padding: 4px;
              @media screen and (max-height: 568px) {
                width: 78px;
                height: 78px;
              }
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
      .button__cta {
        width: 400px;
        align-self: center;
        margin-top: auto;
        margin-bottom: 80px;
        @media screen and (max-width: 960px) {
          width: 100%;
          max-width: 400px;
          margin-bottom: 20px;
        }
        @media screen and (max-height: 568px) {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
