<template>
  <div class="profile-home">
    <div class="content">
      <p class="h6">{{ username }}</p>
      <ul>
        <!-- Status -->
        <li @click="showComponent('Profile-Status')">
          <div class="desc">Status</div>
          <div class="arrow">
            <div class="status-indicator">
              <div class="indicator" :class="statusIndicator"></div>
              <div class="status">{{ statusDescription }}</div>
            </div>
            <img src="@/assets/icons/Arrow_Right.svg" />
          </div>
        </li>
        <!-- Avatar -->
        <li @click="showComponent('Profile-Avatar')">
          <div class="desc">Avatar ändern</div>
          <div class="arrow">
            <img src="@/assets/icons/Arrow_Right.svg" />
          </div>
        </li>
        <!-- Change Password -->
        <li @click="showComponent('Profile-Password')">
          <div class="desc">Passwort ändern</div>
          <div class="arrow">
            <img src="@/assets/icons/Arrow_Right.svg" />
          </div>
        </li>
        <!-- Blocked Users -->
        <li @click="showComponent('Profile-Blocked-Users')">
          <div class="desc">Blockierte Nutzer</div>
          <div class="arrow">
            <img src="@/assets/icons/Arrow_Right.svg" />
          </div>
        </li>
        <!-- Delete Account -->
        <li @click="showComponent('Profile-DeleteAcc')">
          <div class="desc">Account löschen</div>
          <div class="arrow">
            <img src="@/assets/icons/Arrow_Right.svg" />
          </div>
        </li>
        <!-- Privacy Policy -->
        <li @click="showComponent('Profile-Legal')">
          <div class="desc">Impressum und Datenschutz</div>
          <div class="arrow">
            <img src="@/assets/icons/Arrow_Right.svg" />
          </div>
        </li>
        <!-- Licenses -->
        <li @click="showComponent('Profile-Licenses')">
          <div class="desc">Lizenzen</div>
          <div class="arrow">
            <img src="@/assets/icons/Arrow_Right.svg" />
          </div>
        </li>

        <!-- Logout -->
        <li @click="logout">
          <div class="desc">Logout</div>
          <div class="arrow">
            <img src="@/assets/icons/Arrow_Right.svg" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Profile-Home",
  data() {
    return {
      username: this.$store.getters.USER.username,
    };
  },
  methods: {
    showComponent(name) {
      this.$store.commit("SET_PROFILE_COMPONENT", name);
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        localStorage.clear();
        this.$router.push("/");
      });
    },
  },
  computed: {
    statusIndicator() {
      const status = 0;

      switch (status) {
        case 0:
          return "online";
        case 1:
          return "busy";
        case 2:
          return "dontDisturb";
        default:
          return "online";
      }
    },
    statusDescription() {
      const status = 0;

      switch (status) {
        case 0:
          return "Online";
        case 1:
          return "Beschäftigt";
        case 2:
          return "Bitte nicht stören";
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_globals.scss";

.profile-home {
  min-height: calc(100vh - 83px);
  padding: 200px 40px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 960px) {
    padding: 92px 0px;
  }
  .content {
    width: 640px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 960px) {
      width: 100%;
      max-width: 640px;
      margin-top: 40px;
    }
    .h6 {
      margin: 0 0 32px 24px;
    }
    ul {
      li {
        list-style-type: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-top: 1px solid $light-grey;
        border-bottom: 1px solid $light-grey;
        padding: 14px 24px;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        &:hover {
          background-color: $light-grey;
        }
        &:nth-of-type(even) {
          border-bottom: none;
          border-top: none;
        }
        &:last-of-type {
          border-bottom: 1px solid $light-grey;
          border-top: 1px solid $light-grey;
          margin-top: 72px;
        }
        .desc {
          font-size: 17px;
        }
        .arrow {
          display: flex;
          align-items: center;
          img {
            height: 24px;
          }
          .status-indicator {
            display: flex;
            align-items: center;
            .indicator {
              width: 12px;
              height: 12px;
              border-radius: 100%;
              background-color: $green;
            }
            .status {
              margin: 0 24px 0 8px;
            }
            .online {
              background-color: $green;
            }
            .busy {
              background-color: $yellow;
            }
            .dontDisturb {
              background-color: $red;
            }
          }
        }
      }
    }
  }
}
</style>
